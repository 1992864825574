import { apiService } from '../services/api-service';
import { getToken } from './getToken';

// NOTE(drew): It _should_ be safe to set the token as often as we like, but
// since prior behavior was to only set it once during main app module
// initialization, I am preserving that behavior.
let setAtLeastOnce = false;

export function setAPIServiceClientSecureToken(force = false) {
  if (setAtLeastOnce && !force) return;
  setAtLeastOnce = true;
  try {
    apiService.setSecureToken(getToken());
  } catch (error) {
    console.error(error);
  }
}
